import { Button, Checkbox, Col, Form, Image, Input, Row, Typography, notification } from "antd";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";
import GoogleLogo from "@/assets/images/google_logo.png";
import styles from "./login.module.less";
import { getError, isAuthenticated, isAuthorized, setLocalStorage } from "@/utils/helpers";
import ApiServices from "@/utils/api";
import C from "@/utils/constants";
import AuthLayout from "@/components/layouts/AuthLayout";
import routes from "@/routes";
import message from "@/utils/message";

const { Title, Text } = Typography;

function LoginModule() {
  const history = useHistory();

  const onFinish = async (values: any) => {
    const { username, password, remember } = values;
    const api = new ApiServices();
    try {
      const res = await api.signIn({
        username,
        password,
      });

      if (res.errors) {
        notification.error({
          key: "error_login",
          message: res.message,
        });
        return;
      }

      if (!isAuthorized(res.platform)) {
        notification.error({
          key: "error_login",
          message: "You are not authorized to access this page.",
        });
        return;
      }

      setLocalStorage(C.STORAGE_TOKEN, res.accessToken);
      if (remember) {
        setLocalStorage(C.STORAGE_REFRESH_TOKEN, res.refreshToken);
      }
      history.push("/");
    } catch (err: any) {
      notification.error({
        key: "error_login",
        message: getError(err),
      });
    }
  };

  useEffect(() => {
    console.log("prod");
    if (isAuthenticated()) {
      history.push("/");
    }
  }, [history]);

  return (
    <AuthLayout title={routes.login.title}>
      <Title
        level={1}
        style={{
          marginBottom: 16,
          fontWeight: 700,
          color: "#000000",
        }}
      >
        Sign in to Stocktake
      </Title>
      <Text
        className={styles.textBase}
        style={{
          marginBottom: 12,
          color: "#454545",
        }}
      >
        Please enter your details below
      </Text>
      <Button className={styles.btnSignInGoogle} size="large">
        <Image preview={false} src={GoogleLogo} width={24} />
        <Text className={styles.textBase}>Sign in with Google</Text>
      </Button>
      <div className={styles.orDividerWrap}>
        <div className={styles.lineDecoration} />

        <Text>or</Text>
        <div className={styles.lineDecoration} />
      </div>

      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: false }}
        onFinish={onFinish}
        style={{
          width: 365,
          display: "flex",
          flexDirection: "column",
          gap: 12,
        }}
      >
        <Form.Item name="username" rules={[{ required: true, message: message.ERROR_NOT_BLANK("Username") }]}>
          <Input size="large" placeholder="Username..." className={styles.textField} />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: message.ERROR_NOT_BLANK("Password") }]}
          style={{
            marginBottom: "0px !important",
          }}
        >
          <Input.Password size="large" type="password" placeholder="Password..." className={styles.textField} />
        </Form.Item>
        <Form.Item>
          <Row justify="space-between">
            <Col>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>
                  <Text className={clsx(styles.textSmall, styles.textFormColor, "h-auto")}>Remember me</Text>
                </Checkbox>
              </Form.Item>
            </Col>
            <Col>
              <Link
                className={clsx(styles.textSmall, styles.textFormColor, "login-form-forgot")}
                to="/forgot-password"
                style={{
                  textDecoration: "underline",
                  textUnderlineOffset: 4,
                }}
              >
                Forgot password
              </Link>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{
              width: "100%",
              marginBottom: 16,
            }}
            size="large"
          >
            <Title
              level={5}
              style={{
                color: "#fff",
                margin: 0,
              }}
            >
              Log in
            </Title>
          </Button>

          <div
            style={{
              textAlign: "center",
            }}
          >
            <Text className={styles.textSmall}>
              <span
                style={{
                  color: "#C4C4C4",
                }}
              >
                Don’t have an account?
              </span>{" "}
              <span
                style={{
                  color: "#000000",
                }}
              >
                Sign up for free
              </span>
            </Text>
          </div>
        </Form.Item>
      </Form>
    </AuthLayout>
  );
}

export default LoginModule;
